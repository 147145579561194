@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

.avlegalfirm-navbar {
	position: absolute;
	width: 100%;
	z-index: 2;

	&--topContainer {
		color: white;
		background-color: $blue;

		&_contact {
			height: 50px;
			max-width: 1200px;
			width: 100%;
			margin: 0 auto;
			@include flex-row;
			@include flex-vertical-center;
			justify-content: flex-end;
			gap: 50px;

			a {
				@include av-link(white);
			}
		}

		.dropdown-btn {
			background-color: $blue;
		}

	}

	&--header {
		height: 80px;
		display: flex;
		justify-content: center;

		&_links {
			position: relative;
			display: flex;
			width: 100%;
			max-width: 1200px;
			justify-content: flex-end;
			align-items: center;
			gap: 30px;

			svg {
				position: absolute;
				left: 0;
				cursor: pointer;
			}

			p,
			span {
				font-weight: 500;
				font-size: 16px;
			}

			

			p {
				text-decoration: none;
				@include av-link(white);
			}

			&-active {
				text-decoration: underline !important;
				text-decoration-color: $gold !important;
				text-decoration-thickness: 2px !important;
				text-underline-offset: 5px;
			}

		}

	}

	.sticky {
		position: fixed;
		z-index: 1;
		top: 0;
		background-color: white;
		box-shadow: 0 0 20px -10px rgb(0 0 0 / 80%);
		color: $blue;
		width: 100%;
		box-sizing: border-box;

		p {
			@include av-link(black);
		}

		span, li {
			color: black;
		}
	}

}

@media (max-width: 1300px) {
	.avlegalfirm-navbar {

		&--topContainer,
		&--header {
			padding: 0 40px;
		}
	}
}

@media (max-width: 920px) {
	.avlegalfirm-navbar {
		// display: none;
		&--header {
			height: 60px;
			padding: 0 20px;
			#AVLogo {
				width: 50px;
			}
			&_links {
				p{display: none;}
				.dropdown-btn {
					padding: 5px;
					border: 1px solid  white;
					border-radius: 5px;
					
				}
			}
		}

		.sticky { 
		
				.dropdown-btn {
					border: 1px solid #ccc;
				}
			
		}

	}
}

@media screen and (max-width: $breakpoint-tablet) {


}