@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#memberCV {
    margin-top: 100px;
    padding: 0 20px;

    h1,
    h2 {
        font-family: 'Times New Roman', Times, serif;
    }

    .swiper {
        &-pagination {
            &-bullets {
                top: 0;
                bottom: auto;
            }

            &-bullet-active {
                background-color: $blue;
            }
        }

        &-button {

            &-prev,
            &-next {
                top: 60px;
                color: $blue;
            }

            &-prev {
                left: auto;
                right: 70px;
            }
        }
    }

    .avlegalfirm-popupBox {

        &--cv {
            @include flex-column;
            margin-top: 40px;

            &_contactData {
                @include flex-column;

                h1,
                h2 {
                    margin-bottom: 1rem;
                }

                img {
                    object-fit: cover;
                    height: auto;
                    background-color: $blue;
                    border-radius: 10px;
                    max-width: 300px;
                    align-self: center
                }

                &-row {
                    @include flex-row;
                    @include flex-vertical-center;
                    gap: 10px;

                    &--phone {
                        p {
                            margin: 0
                        }
                    }
                }

            }

            &_professionalData {
                @include flex-column;

                &-list--wrapper {
                    flex-direction: column;

                    ul {
                        padding-left: 1em;

                        li {

                            &::marker {
                                color: $gold;
                            }

                            &:not(:last-child) {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }

            }

        }
    }
}


@media screen and (min-width: $breakpoint-tablet) {
    #memberCV {
        .avlegalfirm-popupBox {
            &--cv {
                flex-direction: row;
                gap: 40px;

                &_contactData {
                    width: 25%;

                    img {
                        align-self: auto;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    #memberCV {
        padding: 0 40px;
    }
}