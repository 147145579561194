@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#aboutUs {
    background-color: rgb(228, 228, 228);
    max-width: none;

    .avlegalfirm-aboutUs {
        padding: 0;
        margin: 0 auto;
        max-width: 1200px;
        color: black;
        margin-bottom: 100px;

        &--container {
            @include flex-column;
            align-items: center;
            gap: 40px;
            padding-top: 40px;

            h2 {
                text-align: left;
            }

            &_img {
                @include flex-column;
                align-items: center;
                justify-content: space-between;
                max-width: 600px;

                img,
                svg {
                    width: 100%;
                    height: auto;
                }

                svg {
                    max-width: 120px;
                    margin-bottom: 30px;
                }
            }

            &_text {
                h1 {
                    text-align: center;
                }

                #p-0 {
                    margin-top: 50px;
                }

                &-aboutUs {
                    font-family: "Times New Roman", Times, serif;
                }

                &-row {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    gap: 20px;
                    margin-bottom: 20px;

                    &--icon {
                        @include flex-row;
                        @include flex-horizontal-center;
                        @include flex-vertical-center;
                        background-color: whitesmoke;
                        color: $gold;
                        border-radius: 100%;
                        width: 50px;
                        height: 50px;
                    }

                    label {
                        font-weight: bold;
                    }

                    p {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: $breakpoint-tablet) {
    #aboutUs {
        .avlegalfirm-aboutUs {
            &--container {
                &_img {
                    svg {
                        max-width: 134px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    #aboutUs {
        .avlegalfirm-aboutUs {
            &--container {
                flex-direction: row-reverse;
                align-items: inherit;

                h1 {
                    text-align: left;
                }

                &_img {
                    width: 50%;
                    max-width: none;

                    svg {
                        max-width: 158px;
                    }
                }

                &_text {
                    width: 50%;
                }
            }
        }
    }
}