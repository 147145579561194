@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#practiceAreas {
    .avlegalfirm-practiceAreas {
        &--cardsContainer {
            @include grid-cards(1);
            gap: 20px;

            &_card {
                background: linear-gradient(to bottom, $blue, $dark-blue);
                display: flex;
                border-radius: 20px;
                flex-direction: column;
                color: white;
                padding: 40px 20px;

                &-icon {
                    display: flex;
                    justify-content: center;
                    background-color: $dark-transparent-blue;
                    border-radius: 100%;
                    width: 80px;
                    height: 80px;
                    align-self: center;
                }
            }

            svg,
            h3 {
                align-self: center;
            }
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    #practiceAreas {
        .avlegalfirm-practiceAreas {
            &--cardsContainer {
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                &_card {
                    padding: 20px 40px;
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    #practiceAreas {
        .avlegalfirm-practiceAreas {
            &--cardsContainer {
               
                gap: 40px;

                &_card {
                    padding: 40px;
                }
            }
        }
    }
}