@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

.avlegalfirm-container {
    h1 {
        text-transform: uppercase;
        font-family: "Times New Roman", Times, serif;
        font-size: 24px;
        margin: 0;
    }

    p {
        text-align: justify;
        margin: 0;
    }

    .avlegalfirm {
        &-section {
            max-width: 1200px;
            box-sizing: border-box;
            margin: 0 auto;
            padding: 0 20px;
            margin-bottom: 80px;

            &--title1 {
                color: $gold;
                text-align: center;
                margin: 0px;
            }

            &--title2 {
                text-align: center;
                color: black;
                font-size: 22px;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }

        &-active--card {
            @include card-hover;
        }
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .avlegalfirm-container {
        h1 {
            font-size: 28px;
        }

        .avlegalfirm {
            &--title1 {
                font-size: 28px;
            }

            &--title2 {
                font-size: 24px;
            }
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .avlegalfirm-container {
        h1 {
            font-size: 32px;
        }

        .avlegalfirm {
            &-section {
                padding: 0;

                &--title1 {
                    font-size: 32px;
                }

                &--title2 {
                    font-size: 26px;
                }
            }
        }
    }
}


@media screen and (min-width: $breakpoint-desktop ) and (max-width: 1240px) {
    .avlegalfirm-container {
        .avlegalfirm {
            &-section {
                padding: 0 40px;
            }
        }
    }
}


/* Animations */
@for $i from 1 through 5 {
    .fadeInAnimation-#{$i} {
        @include fadeInAnimation(#{$i}s)
    }

    .bottomToTopAnimation-#{$i} {
        @include bottomToTopAnimation(#{$i}s)
    }

    .leftToRightAnimation-#{$i} {
        @include leftToRightAnimation(#{$i}s,1s)
    }

    .leftToRightAnimation-#{$i} {
        @include leftToRightAnimation(#{$i}s, 1s)
    }

    .rightToLeftAnimation-#{$i} {
        @include rightToLeftAnimation(#{$i}s)
    }

    .growAnimation-#{$i} {
        @include growAnimation(#{$i}s)
    }
}