@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#welcomePage {

    .avlegalfirm-welcomePage {
        @include flex-column;
        align-items: center;


        &--text {
            @include flex-column;
            @include flex-vertical-center;
            text-align: justify;
            flex: 1;
            padding: 0 20px;
            margin-bottom: 2rem;

            h1 {
                text-align: center;
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
        }

        &--img {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (min-width: $breakpoint-desktop) {

    #welcomePage {
        .avlegalfirm-welcomePage {
            flex-direction: row;

            &--img {
                img {
                    max-height: 475px;
                    width: auto;
                    display: block;
                }
            }

            &--text {
                padding: 0 40px;
                margin-bottom: 0;

                h1 {
                    margin-top: 0;
                }
            }

            p {
                max-width: 700px;
            }
        }
    }
}