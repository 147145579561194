@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#contactUs {
    .avlegalfirm-contactUs {
        &--container {
            // @include flex-row;
            @include flex-column;
            box-sizing: border-box;
            margin-top: 50px;

            &_links {
                // background: linear-gradient(to bottom, $blue, $dark-blue);
                // color: white;
                // border-radius: 20px;
                // padding: 40px;
                @include flex-column;
                // max-width: 400px;


                &-item {
                    margin-top: 25px;
                    display: flex;
                    flex-direction: row;
                    

                    a {
                        // @include av-link(white);
                        @include av-link($blue);
                        text-decoration: underline;
                    }
                }
            }

            &_form {
                margin-left: 40px;
                flex: 1;

                form {
                    @include flex-column;
                }

                &-row {
                    @include grid-cards(2);
                    gap: 3%;
                }

                input,
                textarea {
                    font-family: inherit;
                    border: 1px solid gray;
                    border-radius: 5px;
                    padding: 10px;
                    box-sizing: border-box;
                    margin: 5px 0 20px 0;

                }

                input,
                textarea,
                label {
                    width: 100%;
                }

                button {
                    background-color: $blue;
                    min-width: 250px;
                    color: white;
                    height: 50px;
                    border-radius: 10px;
                    border: none;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

        }
    }
}

/* For Tablet View */
@media screen and (max-width: 920px) {
    #contactUs {
        .avlegalfirm-contactUs {
            &--container {
                &_form {
                    &-row {
                        @include grid-cards(1);
                        gap: 0;
                    }
                }
            }
        }
    }
}

/* For Mobile View */
@media screen and (max-width: 640px) {
    #contactUs {
        .avlegalfirm-contactUs {
            &--container {
                flex-direction: column;

                &_links {
                    color: black;
                    background: none;
                    padding: 0;
                    max-width: none;
                    margin-bottom: 40px;

                    &-item {
                        a {
                            @include av-link($blue);
                            text-decoration: underline;
                        }
                    }

                }

                &_form {
                    margin: 0 0 40px;
                }
            }
        }
    }
}