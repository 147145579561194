@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#footer {
	background-color: $blue;
	color: white;
	position: relative;
	margin-top: 160px;

	p {
		font-size: 13px;
		width: 100%;
		text-align: center;
		padding: 10px;
		margin: 0;
		background-color: $dark-blue;
		box-sizing: border-box;
	}

	.avlegalfirm-footer {
		display: flex;
		width: 100%;
		max-width: 1200px;
		align-items: flex-end;
		margin: 0 auto;
		flex-direction: column;
		align-items: center;
		border-radius: 80% 20% 52% 68% / 58% 22% 78% 42%;

		svg {
			vertical-align: middle;
			color: white;
		}

		&--contact {
			display: flex;
			gap: 40px;
			margin: 25px 0;

			svg {
				color: $gold;

				&:hover {
					@include scale-transition;
				}
			}

			@include av-link(white);
		}
	}
}