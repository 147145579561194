@import "../../utils/colors.scss";

.dropdown {
  position: relative;
  display: inline-block;

  &-btn {
    background-color: transparent;
    color: white;
    border: none;
    font-size: 16px;
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        position: relative !important;
        margin-left: 5px;
      }
    }
  }

  &-menu {
    position: absolute;
    z-index: 1;
    list-style: none;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0;
    border: 1px solid #ccc;
    overflow: hidden;
    width: 100%;
    border-radius: 5px;

    li {
      padding: 8px;
      font-size: 16px;
      cursor: pointer;
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        svg {
          position: relative !important;
          margin-left: 5px;
        }
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}