@import "./colors.scss";

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-large: 1440px;

@for $i from 0 through 25 {
  .mb-#{$i * 5} {
    margin-bottom: $i * 5px !important;
  }

  .mt-#{$i * 5} {
    margin-top: $i * 5px !important;
  }
}


@mixin flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

@mixin flex-vertical-center {
  align-items: center;
}

@mixin flex-horizontal-center {
  justify-content: center;
}



@mixin flex-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@mixin av-link($color) {
  cursor: pointer;
  text-decoration: none;
  color: $color;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 15px;
  }
}

@mixin card-hover {
  cursor: pointer;

  svg {
    transition: 0.5s;
    color: $gold;
  }

  h3 {
    color: $gold2;
  }
}

@mixin scale-transition {
  transition: all .2s ease-in-out;
  transform: scale(1.1)
}

@mixin grid-cards($colums) {
  display: grid;
  grid-template-columns: repeat($colums, 1fr);
  grid-auto-rows: auto;
}

@mixin primary-button {
  padding: 8px 30px;
  color: white;
  background-color: $dark-blue;
  ;
  border: 2px solid white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

@mixin fadeInAnimation($duration: 1s, $delay: 0s) {
  opacity: 0;
  animation: fadeIn $duration ease-in-out $delay forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin topToBottomAnimation($duration: 1s) {
  overflow: hidden;
  animation: topToBottom $duration ease-in-out 0s forwards;

  @keyframes topToBottom {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@mixin bottomToTopAnimation($duration: 1s, $delay: 0s) {
  opacity: 0;
  transform: translateY(20px);
  animation: fromBottom $duration ease-in-out $delay forwards;

  @keyframes fromBottom {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin rightToLeftAnimation($duration: 1s, $delay: 0s) {
  opacity: 0;
  transform: translateX(100%);
  animation: rightToLeft $duration $delay forwards;

  @keyframes rightToLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@mixin leftToRightAnimation($duration: 1s, $delay: 0s) {
  opacity: 0;
  animation: leftToRight $duration ease-in $delay forwards;

  @keyframes leftToRight {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@mixin growAnimation($duration: 1s) {
  opacity: 0;
  animation: grow $duration 0s forwards;

  @keyframes grow {
    0% {
      opacity: 0;
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}