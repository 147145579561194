@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#banner {
    background: radial-gradient(circle, $blue 0%, $dark-blue 100%);
    padding-top: 100px;
    position: relative;

    h1,
    h2,
    h3,
    p,
    svg,
    button {
        @include bottomToTopAnimation(2s);
    }

    img {
        @include fadeInAnimation(3s);
    }

    .avlegalfirm-banner {
        height: 100%;
        @include flex-column;
        @include flex-horizontal-center;
        gap: 40px;
        align-items: center;
        color: white;
        margin-bottom: 0;

        &--contactus {
            @include flex-column;
            @include flex-vertical-center;
            @include flex-horizontal-center;
            width: 40%;
            gap: 20px;

            #avLogo {
                width: auto;
                height: 98px;
            }


            &_etr {
                display: none;
            }

            &_btn {
                @include primary-button;
                width: 220px;
                margin: 15px 0;
            }
        }

        &--team {
            display: flex;
            align-self: flex-end;
            flex: 1;

            img {
                width: 100%;
                height: auto;
            }
        }

        &--cardsContainer {
            display: none;
        }

    }
}

/* Tablet styles */
@media (min-width: $breakpoint-tablet) {
    #banner {
        padding-top: 130px;

        .avlegalfirm-banner {


            &--contactus {

                #avLogo {
                    height: 116px;
                }

                &_etr {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    h1 {
                        font-size: 20px;
                    }

                }

                &_btn {
                    margin: 0;
                }
            }

            &--team {
                align-self: center;
                margin-top: 20px;
            }

            &--cardsContainer {
                display: flex;
                justify-content: center;
                width: 100%;
                color: white;
                background-color: white;
                margin: 0 auto;
                padding: 2px 0;
                gap: 2px;

                &_card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 40px;
                    background-color: $dark-blue;
                    width: 100%;
                    @include card-hover;

                    &:nth-child(2) {
                        background-color: $blue;
                    }

                    p {
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (min-width: $breakpoint-desktop) {
    #banner {
        .avlegalfirm-banner {
            flex-direction: row;
            gap: 0;

            &--contactus {
                #avLogo {
                    height: 117px;
                }

                &_etr {
                    font-size: 15px;
                }
            }
        }
    }
}