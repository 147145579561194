@import "../../utils/colors.scss";
@import "../../utils/general-styles.scss";

#team {
    .avlegalfirm-team {
        &--description {
            margin-bottom: 50px;
            text-align: justify;
        }

        &--container {
            @include grid-cards(1);
            &_member {
                background: linear-gradient(to bottom, $blue, $dark-blue);
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 20px;
                position: relative;
                cursor: default;
                padding: 40px 20px 80px;
                height: 60vh;
                max-height: 450px;

                &-seeMore {
                    color: $gold2;
                    background-color: transparent;
                    border: none;
                    margin-top: 2rem;
                    margin-bottom: 40px;
                    padding: 0;
                    border-bottom: 1px solid;

                    span {
                        font-size: 14px;
                        display: flex;
                        align-items: center;

                        svg {
                            margin-left: 7px;
                        }
                    }
                }

                &-pic {
                    border-radius: 100%;
                    width: 65%;
                    max-width: 250px;
                    @include flex-row;
                    @include flex-vertical-center;
                    @include flex-horizontal-center;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                        background-color: $dark-transparent-blue;
                    }
                }

                &-info {
                    color: white;
                    margin: 20px 0 5px;

                    p,
                    h3 {
                        text-align: center;
                    }

                    p:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }

                &-contacts {
                    @include flex-row;
                    @include flex-horizontal-center;
                    gap: 15%;
                    border-top: 2px solid rgba($color: $gold, $alpha: 0.7);
                    width: 100%;
                    padding-top: 20px;

                    svg {
                        &:hover {
                            @include scale-transition;
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            &-bullets {
                bottom: 40px;
            }

            &-bullet-active {
                background-color: whitesmoke;

                &-prev,
                &-prev-prev,
                &-next,
                &-next-next {
                    background-color: whitesmoke;
                    opacity: 0.7;
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    #team {
        .avlegalfirm-team {
            &--container {
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                &_member {
                    padding: 40px 20px;
                    height: auto;

                    &-pic {
                        width: 100%;
                    }
                }

            }
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    #team {
        .avlegalfirm-team {
            &--container {
                gap: 40px;

                &_member {
                    padding: 40px;
                }

            }
        }
    }
}